export const AdminUserGroups = [
	"admin",
	"standard",
	"external"
]

export class AdminUser {

	public email: string | null = null
	public active: boolean = true
	public groups: string | null = null

	constructor(
		public adminCognitoId: string,
		public firstName: string,
		public lastName: string
	) { }

	get fullName(): string {
		if (!this.firstName) {
			return null
		} else if (!this.lastName) {
			return this.lastName
		}
		return `${this.firstName} ${this.lastName}`
	}

	static fromJson(data: any): AdminUser {
		return new AdminUser(
			data.adminCognitoId,
			data.firstName,
			data.lastName
		)
	}

	isValidForCreate(): boolean {
		return (
			this.email && this.email.length > 0 &&
			this.email.includes("@") &&
			this.firstName && this.firstName.length > 0 &&
			this.lastName && this.lastName.length > 0 &&
			this.groups && this.groups.length > 0 &&
			this.groups.split(",").every((group) => AdminUserGroups.includes(group)) &&
			typeof this.active === "boolean"
		)
	}
}
