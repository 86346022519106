<div class="header">
	<h1>Payers</h1>
	<div>
		<a class="button" [routerLink]="['/create-payer']">Create Payer</a>
	</div>
</div>
<div class="payers">
	<table>
		<thead>
			<td>Payer</td>
			<td>Sohar ID</td>
			<td>Payer ID</td>
			<td>Type</td>
			<td>Discovery</td>
			<td>Clearing House</td>
			<td>Public</td>
		</thead>
		<tbody *ngFor="let payer of payers">
			<tr>
				<td>
					<a [routerLink]="['/payers', payer.payerId]">{{ payer.payerName }}</a>
				</td>
				<td>{{ payer.payerId }}</td>
				<td>{{ payer.standardPayerId }}</td>
				<td>{{ payer.payerType }}</td>
				<td>{{ payer.discovery == true ? "Yes" : "No" }}</td>
				<td>{{ payer.clearingHouse }}</td>
				<td>{{ payer.isCustomerPayer ? "Yes" : "No" }}</td>
			</tr>
		</tbody>
	</table>
</div>