import { Component, OnDestroy } from '@angular/core'
import { AdminService } from "../@shared/services/admin.service"
import { Title } from "@angular/platform-browser"
import { ActivatedRoute, Router } from '@angular/router'
import { SpecialtyModel } from "../@shared/models/specialtyModel"
import { TabManagerService } from '../@shared/services/tab-manager.service'

@Component({
  selector: 'app-specialty',
  templateUrl: './specialty.component.html',
  styles: [`
    .disabled {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }
  `]
})
export class SpecialtyComponent extends TabManagerService implements OnDestroy {

  specialty: any|null = null
  models: SpecialtyModel[] = []
  codes: any[] = []
  taxonomyCodes: any[] = []
  code: string|null = null

  constructor(
    private adminService: AdminService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title
  ) {
    super(router)
  }

  async ngOnInit() {
    this.titleService.setTitle("Specialties")

    const specialtyId = this.route.snapshot.paramMap.get("id")

    this.getSpecialty(specialtyId)
  }

  ngOnDestroy() {
    this.destroy()
  }

  async getSpecialty(specialtyId: string) {
    const res = await this.adminService.getSpecialty(specialtyId)

    this.specialty = res.specialty
    this.models = res.models || []
    this.codes = res.codes || []
    this.taxonomyCodes = res.taxonomyCodes || []
  }

  async didClickAddCode() {
    const specialtyId = this.specialty?.specialtyId
    const code = this.code

    if (specialtyId == null ||
      specialtyId == undefined) {
      return
    } else if (code == null ||
      code == undefined ||
      code == "") {
      alert("Enter a CPT code")
      return
    }

    try {
      await this.adminService.createSpecialtyCode(
        specialtyId,
        code)

      this.code = null

      this.getSpecialty(specialtyId)
    } catch (error: any) {
      alert("Something went wrong")
      console.log(error)
    }
  }

  async didClickUpdateModel(model: SpecialtyModel) {
    const specialtyId = model.specialtyId
    const modelId = model.modelId
    const status = model.status == 0 ? 1 : 0

    try {
      const res = await this.adminService.updateSpecialtyModel(
        specialtyId,
        modelId,
        status)

      if (res == null ||
        res == undefined ||
        res.model == null ||
        res.model == undefined) {
        return
      }

      model.status = res.model.status
    } catch (error: any) {
      alert("Something went wrong")
      console.log(error)
    }
  }
}
