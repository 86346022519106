import { Component } from '@angular/core';
import { PayerIntelligenceService } from "../@shared/services/payer-intelligence.service"
import { Title } from "@angular/platform-browser"
import { PayerRoute } from "../@shared/models/payer-route"
import { Payer } from "../@shared/models/payer"
import { AdminService } from "../@shared/services/admin.service"

@Component({
  selector: 'app-payer-routing',
  templateUrl: './payer-routing.component.html'
})
export class PayerRoutingComponent {

  rules: PayerRoute[] = []

  pattern: string|null = null
  payerId: string = ""
  payers: Payer[] = []

  constructor(
    private payerIntelligenceService: PayerIntelligenceService,
    private titleService: Title,
    private adminService: AdminService
  ) {}

  async ngOnInit() {
    this.titleService.setTitle("Routing - Payer Intelligence")

    this.getRules()

    this.payers = await this.adminService.getPayers()
  }

  async getRules() {
    this.rules = await this.payerIntelligenceService.getPayerRouteRules()
  }

  async didClickDeleteRule(id: number) {
    if (id == null ||
      id == undefined) {
      return
    }

    const confirmed = confirm("Are you sure you want to delete this rule?")

    if (!confirmed) {
      return
    }

    try {
      await this.payerIntelligenceService.deletePayerRouteRule(id)
      alert("Rule deleted")
      this.getRules()
    } catch (error: any) {
      alert("Something went wrong")
    }
  }

  didClickSetPattern(event: any) {
    if (event == null ||
      event == undefined) {
      return
    }

    const target = event.target as HTMLElement

    if (target == null ||
      target == undefined) {
      return
    }

    this.pattern = target.innerHTML
  }

  async didClickAddRule() {
    const pattern = this.pattern
    const payerId = this.payerId

    if (pattern == null ||
      pattern == undefined ||
      pattern == "") {
      alert("Enter a regex pattern")
      return
    } else if (payerId == null ||
      payerId == undefined ||
      payerId == "") {
      alert("Select a payer")
      return
    }

    try {
      await this.payerIntelligenceService.createPayerRouteRule(pattern, payerId)
      alert("Rule created")
      this.getRules()
    } catch (error) {
      alert("Something went wrong")
    } finally {
      this.pattern = null
      this.payerId = ""
    }
  }
}
