<div class="header">
	<h1 *ngIf="verification != null">{{ verification.memberId }} - {{ verification.status }}</h1>
	<div>
		<a class="button" (click)="didClickRetryVerification()">Retry Verification</a>
		<a class="button" [routerLink]="['/create-payer']">Create Payer</a>
	</div>
</div>
<ul class="tabs">
	<li [class.active]="tab == 'detail'"><a (click)="navigateToTab('detail')">Detail</a></li>
	<li [class.active]="tab == 'events'" *ngIf="!isExternalUser"><a (click)="navigateToTab('events')">Events</a></li>
	<li [class.active]="tab == 'related-entities'" *ngIf="!isExternalUser"><a (click)="navigateToTab('related-entities')">Related Entities</a></li>
	<li [class.active]="tab == 'notes'"><a (click)="navigateToTab('notes')">Notes</a></li>
</ul>
<div *ngIf="verification && adminData">
	<div *ngIf="tab == 'detail'">
		<app-verification-detail
			[verification]="verification"
			[data]="adminData"
			[payers]="payers"
		/>
	</div>
	<div *ngIf="tab == 'events'">
		<app-verification-events
			[verification]="verification"
		/>
	</div>
	<div *ngIf="tab == 'related-entities'">
		<app-verification-related-entities
			[verification]="verification"
		/>
	</div>
	<div *ngIf="tab == 'notes'">
		<app-verification-notes
			[verificationId]="verification.verificationId"
		/>
	</div>
</div>
