import { Component, Input, Output, EventEmitter } from '@angular/core'
import { PayerMapping } from "../../models/payer-mapping"
import { Payer } from "../../models/payer"
import { PayerIntelligenceService } from "../../services/payer-intelligence.service"
import { ENTITY_IDENTIFIERS } from "../../constants/data"

@Component({
  selector: 'payer-mapping-rules',
  templateUrl: './payer-mapping-rules.component.html'
})
export class PayerMappingRulesComponent {

  @Input() rules: PayerMapping[] = []

  @Input() payers: Payer[] = []

  @Output() updateRule = new EventEmitter<number>()

  entityIdentifiers = ENTITY_IDENTIFIERS

  constructor(
    private payerIntelligenceService: PayerIntelligenceService
  ) {}

  async didClickOpen(rule: PayerMapping) {
    if (rule.isOpen) {
      rule.isOpen = false
      return
    }

    rule.isOpen = true

    if (rule.data != null) {
      return
    }

    this.updateRule.emit(rule.id)
  }

  async didClickSubmit(rule: PayerMapping) {
    if (rule.selectedRelatedEntityPayerId == null ||
      rule.selectedRelatedEntityPayerId == "") {
      alert("Select a payer")
      return
    } else if (rule.selectedEntityIdentifierCode == null ||
      rule.selectedEntityIdentifierCode == undefined ||
      rule.selectedEntityIdentifierCode == "") {
      alert("Select an entity identifier code")
      return
    }

    try {
      await this.payerIntelligenceService.updatePayerMappingRule(rule.id, rule.selectedRelatedEntityPayerId, rule.selectedEntityIdentifierCode)
      this.updateRule.emit(rule.id)
    } catch (error) {
      alert("Something went wrong")
    }
  }
}
