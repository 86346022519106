import { Injectable } from '@angular/core';
import { NetworkService } from './network.service'
import { map } from 'rxjs/operators'
import { PayerRoute } from "../models/payer-route"
import { PayerDiscovery } from "../models/payer-discovery"
import { PayerMapping } from "../models/payer-mapping"

@Injectable({
  providedIn: 'root'
})

export class PayerIntelligenceService {

  constructor(
    private networkService: NetworkService
  ) {}

  async getPayerRouteRules(): Promise<PayerRoute[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/payer-intelligence/routes`).pipe(
        map((res: any) => {
          return res.payerRouteRules.map((data: any) => {
            return PayerRoute.fromJson(data)
          })
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getPayerDiscoveryRules(): Promise<PayerDiscovery[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/payer-intelligence/discovery`).pipe(
        map((res: any) => {
          return res.discoveryRules.map((data: any) => {
            return PayerDiscovery.fromJson(data)
          })
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getPayerMappingRules(): Promise<PayerMapping[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/payer-intelligence/mapping`).pipe(
        map((res: any) => {
          return res.mappingRules.map((data: any) => {
            return PayerMapping.fromJson(data)
          })
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getPayerMappingRule(id: number): Promise<PayerMapping|null> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/payer-intelligence/mapping/${id}`).pipe(
        map((res: any) => {
          return PayerMapping.fromJson(res.mappingRule)
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async deletePayerRouteRule(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.delete(`admin/payer-intelligence/routes/${id}`).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async deletePayerDiscoveryRule(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.delete(`admin/payer-intelligence/discovery/${id}`).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createPayerRouteRule(
    pattern: string,
    payerId: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/payer-intelligence/routes`, {
        pattern,
        payerId
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createPayerDiscoveryRule(
    pattern: string,
    payerId: string,
    entityIdentifierCode: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/payer-intelligence/discovery`, {
        pattern,
        payerId,
        entityIdentifierCode
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async updatePayerMappingRule(
    id: number,
    relatedEntityPayerId: string,
    entityIdentifierCode: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.put(`admin/payer-intelligence/mapping/${id}`, {
        relatedEntityPayerId,
        entityIdentifierCode
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }
}
