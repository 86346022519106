import { Component, OnDestroy } from '@angular/core'
import { AuthService } from "../@shared/services/auth.service"
import { Verification } from '../@shared/models/verification'
import { AdminService } from '../@shared/services/admin.service'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { TabManagerService } from '../@shared/services/tab-manager.service'
import { Payer } from '../@shared/models/payer'

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html'
})
export class VerificationComponent extends TabManagerService implements OnDestroy {

  verification: Verification | null = null
  adminData = null
  payers: Payer[] = []

  isExternalUser = false

  constructor(
    private adminService: AdminService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    super(router, 'detail')
  }

  async ngOnInit() {
    this.isExternalUser = await this.authService.hasGroup("external")

    const verificationId = this.route.snapshot.paramMap.get("id") as string

    [this.verification, this.adminData, this.payers] = await Promise.all([
      this.adminService.getVerification(verificationId),
      this.adminService.getData(),
      this.adminService.getPayers()
    ])
  }

  ngOnDestroy() {
    this.destroy()
  }
}
