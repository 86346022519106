import { Component, Input, Output, EventEmitter } from '@angular/core';

enum QUOTA_SPECIAL_VALUES {
  NO_LIMIT = 'No Limit',
  BLOCKED = 'Blocked'
}

@Component({
  selector: 'quota-field',
  templateUrl: './quota-field.component.html',
  styles: [`
    .img {
      vertical-align: middle;
      margin-right: 5px;
    }
    .info {
      display: contents;
      vertical-align: middle;
      color: #666;
      font-size: 12px;
    }
    .input {
      width: 25%;
      display: inline;
      margin-bottom: 0px;
    }
    .display {
      display: inline-block;
      margin-right: 0px;
      width: 25%;
    }
  `]
})
export class QuotaFieldComponent {
  @Input() inputQuotaValue: number
  @Output() quotaUpdated = new EventEmitter<number>();
  @Output() editChanged = new EventEmitter<boolean>();

  isEditing = false
  displayValue: string

  ngOnInit() {
    this.displayValue = this.getDisplayValue(this.inputQuotaValue)
  }

  toggleEdit() {
    if (
      this.inputQuotaValue != null && !isNaN(this.inputQuotaValue) &&
      this.inputQuotaValue >= -1 &&
      this.inputQuotaValue % 1 == 0
    ) {
      this.isEditing = !this.isEditing
      if (this.isEditing == false) {
        this.displayValue = this.getDisplayValue(this.inputQuotaValue)
        this.quotaUpdated.emit(this.inputQuotaValue);
      }
      this.editChanged.emit(this.isEditing)
    }
  }

  onQuotaChange(value: number) {
    this.inputQuotaValue = value
  }

  getDisplayValue (value: number): string {
    if (value == -1) {
      return QUOTA_SPECIAL_VALUES.NO_LIMIT
    } else if (value == 0) {
      return QUOTA_SPECIAL_VALUES.BLOCKED
    } else {
      return `${value}`
    }
  }
}
