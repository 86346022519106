import { Component } from '@angular/core'
import { AdminService } from "../@shared/services/admin.service"
import { Title } from "@angular/platform-browser"
import { AdminUser, AdminUserGroups } from '../@shared/models/admin-user'

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styles: [`
    table td {
      text-overflow: unset;
    }
  `]
})

export class TeamComponent {

  admins: AdminUser[] = []
  newAdmin: AdminUser = new AdminUser("", "", "")
  groups = AdminUserGroups

  constructor(
    private adminService: AdminService,
    private titleService: Title
  ) {}

  async ngOnInit() {
    this.titleService.setTitle("Team")

    await this.loadAdmins()
    this.newAdmin.groups = "standard"
  }

  private async loadAdmins() {
    this.admins = await this.adminService.getTeam()
  }

  async didClickCreateAdmin() {
    if (this.newAdmin.isValidForCreate() === false) {
      alert("Invalid admin data")
      return
    }

    try {
      const password = await this.adminService.createAdminUser(this.newAdmin)
      alert(`Password for ${this.newAdmin.email} is: ${password}`)

      await this.loadAdmins()
    } catch (error: any) {
      console.log(error)
      alert("Something went wrong")
    }
  }
}
