import { getClearingHouseName } from "../helpers/clearingHouseName"

export class Event {

	constructor(
		public payerName: string,
		public clearingHouseIdentifier: number|null,
		public timestamp: string,
		public response: any|null,
		public payerId: string,
		public serviceTypeCode: string,
		public responseId: number,
		public statusCode: string|null,
		public clearingHousePayerId: string|null) {}

	public get clearingHouse(): string|null {
		return getClearingHouseName(this.clearingHouseIdentifier)
	}

	public get statusCodeMessage(): string|null {
		if (this.statusCode == null ||
			this.statusCode == undefined) {
			return null
		}

		const statusCodeMap = {
			"408": "Timeout",
			"4": "Complete",
			"18": "Error",
			"19": "Error",
			"400": "Bad Request"
		}

		return statusCodeMap[this.statusCode] || this.statusCode
	}

	static fromJson(data: any): Event {
		return new Event(
			data.payerName,
			data.clearingHouseIdentifier,
			data.timestamp,
			null,
			data.payerId,
			data.serviceTypeCode,
			data.responseId,
			data.statusCode,
			data.clearingHousePayerId)
	}
}
