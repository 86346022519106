import { Component } from '@angular/core';
import { AdminService } from "../@shared/services/admin.service"
import { VerificationsResponse } from "../@shared/models/verifications-response"
import { Verification } from "../@shared/models/verification"
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from "@angular/platform-browser"
import { AuthService } from "../@shared/services/auth.service"
import { AdminUser } from "../@shared/models/admin-user"
import { stringToColor } from "../@shared/helpers/colors"

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})

export class HomeComponent {

  currentVerification: Verification
  verifications: Verification[] = []
  isExternalUser = false
  adminUsers: AdminUser[] = []

  isLoading = false

  isFilterMenuOpen = false

  filtersArr: {
    name: string,
    value: string
  }[] = [
    {
      name: "Status",
      value: "status"
    },
    {
      name: "Customer",
      value: "customerName"
    },
    {
      name: "Assignee",
      value: "adminFullName"
    },
    {
      name: "Payer",
      value: "payerName"
    }
  ]

  filters: {
    [key: string]: string
  } = {
    status: "pending"
  }

  filterValues: {
    [key: string]: {
      name: string,
      value: string
    }[]
  } = {
    "status": [
      {
        name: "Pending",
        value: "pending"
      },
      {
        name: "Complete",
        value: "complete"
      },
      {
        name: "Error",
        value: "error"
      }
    ]
  }

  sortValues: {
    [value: string]: boolean
  } = {}

  async didChangeAssigneeSelection(event: any) {
    const element = event.target as HTMLSelectElement

    const adminCognitoId = element.value

    if (adminCognitoId == null ||
      adminCognitoId == undefined ||
      adminCognitoId == "") {
      return
    }

    const verificationIds: string[] = this.selectedVerifications()
      .map((verification) => {
        return verification.verificationId
      })

    if (verificationIds.length == 0 ||
      this.isLoading) {
      return
    }

    this.isLoading = true

    await this.adminService.updateAssignees(verificationIds, adminCognitoId)

    this.isLoading = false

    this.getVerifications()
  }

  async didChangeStatusSelection(event: any) {
    const element = event.target as HTMLSelectElement

    const status = element.value

    if (status == null ||
      status == undefined ||
      status == "") {
      return
    }

    const res = confirm("Are you sure?")

    if (res == false) {
      return
    }

    const verificationIds: string[] = this.selectedVerifications()
      .filter((verification) => {
        return verification.status.startsWith("pending")
      })
      .map((verification) => {
        return verification.verificationId
      })

    if (verificationIds.length == 0 ||
      this.isLoading) {
      return
    }

    this.isLoading = true

    await this.adminService.updateStatuses(verificationIds, status)

    this.isLoading = false

    this.getVerifications()
  }

  didClickSelectAll() {
    const selected = this.selectedVerifications().length > 0

    for (const row of this.filteredVerifications) {
      row.isSelected = !selected
    }
  }

  selectedVerifications() {
    return this.verifications.filter(row => row.isSelected)
  }

  didSelectFilter(filter: string, value: string) {
    this.filters[filter] = value

    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: {
        status: this.filters["status"],
        payer: this.filters["payerName"],
        customer: this.filters["customerName"]
      }
    })

    if (filter == "status") {
      this.getVerifications()
    }
  }

  constructor(
    private authService: AuthService,
    private adminService: AdminService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title
  ) { }

  async ngOnInit() {
    this.titleService.setTitle("Dashboard")

    const queryParams: any = this.route.snapshot.queryParams

    if (queryParams) {
      if (queryParams.status) {
        this.filters["status"] = queryParams.status
      }
      if (queryParams.payer) {
        this.filters["payerName"] = queryParams.payer
      }
      if (queryParams.customer) {
        this.filters["customerName"] = queryParams.customer
      }
    }

    this.getVerifications()

    this.isExternalUser = await this.authService.hasGroup("external")

    if (this.isExternalUser) {
      this.sortValues["timestamp"] = false
    }

    const data = await this.adminService.getData()

    this.adminUsers = data.adminUsers
  }

  async getVerifications() {
    if (this.isLoading) {
      return
    }

    this.isLoading = true

    const filterValue = this.filters["status"] || "pending"

    const res = await this.adminService.getVerifications(filterValue)

    this.isLoading = false

    this.verifications = res.verifications

    for (const filterValue in this.filterValues) {
      if (filterValue == "status") {
        continue
      }

      this.filterValues[filterValue] = []
    }

    for (const row of this.verifications) {
      row.isSelected = false

      for (const filter of this.filtersArr) {
        if (filter.value == "status") {
          continue
        }

        const key = filter.value
        const value = row[key]

        if (this.filterValues[key] == undefined) {
          this.filterValues[key] = []
        }

        const existingValues = this.filterValues[key].map(obj => obj.value)

        if (!existingValues.includes(value)) {
          this.filterValues[key].push({
            name: value || "None",
            value: value
          })
        }

        if (this.filters[key] == undefined) {
          this.filters[key] = ""
        }
      }
    }

    for (const filter in this.filterValues) {
      if (filter == "status") {
        continue
      }

      this.filterValues[filter] = this.filterValues[filter].sort((a, b) => {
        return a.name > b.name ? 1 : -1
      })

      this.filterValues[filter].unshift({
        name: "Show All",
        value: ""
      })
    }
  }

  get filteredVerifications(): any[] {
    return this.verifications
      .filter((row) => {
        let res = true

        for (const filter in this.filters) {
          const value = row[filter]
          const filterValue = this.filters[filter]

          if (filter == "status") {
            res = res && (filterValue == "" || value.startsWith(filterValue))
          } else {
            res = res && (filterValue == "" || value == filterValue)
          }

          if (res == false) {
            return false
          }
        }

        return res
      })
      .sort((a, b) => {
        let key = "timestamp"
        let sortValue = true

        for (const _key in this.sortValues) {
          if (this.sortValues[_key] == undefined) {
            continue
          }
          key = _key
          break
        }

        if (this.sortValues[key] == false) {
          sortValue = false
        }

        if (a[key] == b[key]) {
          return 0
        }

        const value = sortValue ? -1 : 1

        return a[key] > b[key] ? value : -(value)
      })
  }

  didClickSortBy(value: string) {
    if (this.sortValues[value] == undefined) {
      for (const key in this.sortValues) {
        this.sortValues[key] = undefined
      }
      this.sortValues[value] = true
    } else if (this.sortValues[value] == true) {
      this.sortValues[value] = false
    } else {
      for (const key in this.sortValues) {
        this.sortValues[key] = undefined
      }
    }
  }

  private adminColors: { [key: string]: string } = {}

  getAdminInitialsColor(adminCognitoId: string): string {
    if (!adminCognitoId) {
      return '#E6E6E6';
    }
    if (!this.adminColors[adminCognitoId]) {
      let color = stringToColor(adminCognitoId);
      this.adminColors[adminCognitoId] = color;
    }
    return this.adminColors[adminCognitoId];
  }
}

