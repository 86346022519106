<div class="header">
	<h1>{{ modelId }}</h1>
	<div>
		<a class="button" (click)="didClickDisableAll()">Disable all</a>
	</div>
</div>
<table>
	<thead>
		<td>Payer</td>
		<td>Status</td>
		<td></td>
	</thead>
	<tbody>
		<tr *ngFor="let config of payerConfigs">
			<td>
				<a
					[routerLink]="['/payers', config.payerId]"
				>
					{{ config.payerName }}
				</a>
			</td>
			<td>
				<img *ngIf="config.status == 1" src="assets/check.svg" />
				<img *ngIf="config.status != 1" src="assets/redcross.svg" />
			</td>
			<td>
				<a class="button" (click)="didClickUpdateConfig(config)">{{ config.status == 1 ? "Disable" : "Enable" }}</a>
			</td>
		</tr>
	</tbody>
</table>
