<div class="header">
	<h1>Mapping</h1>
	<div>
		<a class="button" [routerLink]="['/create-payer']">Create Payer</a>
	</div>
</div>

<div class="filters">
	<select [(ngModel)]="state">
		<option value="">
			Show All
		</option>
		<option value="mapped">
			Show Mapped
		</option>
		<option value="pending">
			Show Pending
		</option>
	</select>
	<span class="total">Showing {{ filteredRules.length | number }} of {{ rules.length | number }}</span>
</div>

<payer-mapping-rules [rules]="filteredRules" [payers]="payers" (updateRule)="getRuleForId($event)"></payer-mapping-rules>

<div class="row">
	<section>
		<h4>Payer Mapping</h4>
		<p>
			Payer Mapping rules give meaning to the related entities returned by payers. For example, we might receive a response with a related entity with the following values:
		</p>
		<code>
			"eligibilityOrBenefitInformationCode": "U", "individualOrOrganizationalNameEntityIdentifierCode": "X3", "individualOrOrganizationalNameNameLastOrOrganizationName": "NEW DIRECTIONS"
		</code>
		<p>
			This information is not useful to our customers, but we know that this indicates that Lucet is acting as a Utilization Management Organization. Every combination of related entity values has a unique <code>ID</code>, for example <code>ABC123</code>. The purpose of this feature is to map ID <code>ABC123</code> to <code>Lucet</code>, with the related entity identifier code <code>X3</code>.
		</p>
		<p>
			If a Verification has related entities that are not mapped, it will be set to a <code>pending.carveout</code> status until all related entities have been mapped. The mapped values will be returned to the customer.
		</p>
		<h4>Entity Identifier Codes</h4>
		<p>
			Entity Identifier Codes describe the role of the payer. If a Payer Discovery request is successful, the specified entity identifier code will be returned to the customer. <a href="https://ediacademy.com/blog/x12-n101-entity-identifier-codes/" target="_blank">This</a> is a useful resource for available entity identifier codes. We currently support the following entity identifier codes:
		</p>
		<table>
			<tr>
				<td>
					<code>PRP</code>
				</td>
				<td>Primary Payer</td>
			</tr>
			<tr>
				<td>
					<code>SEP</code>
				</td>
				<td>Secondary Payer</td>
			</tr>
			<tr>
				<td>
					<code>TTP</code>
				</td>
				<td>Tertiary Payer</td>
			</tr>
			<tr>
				<td>
					<code>X3</code>
				</td>
				<td>Utilization Management Organization</td>
			</tr>
			<tr>
				<td>
					<code>VN</code>
				</td>
				<td>Vendor</td>
			</tr>
			<tr>
				<td>
					<code>TV</code>
				</td>
				<td>Third Party Administrator</td>
			</tr>
			<tr>
				<td>
					<code>Y2</code>
				</td>
				<td>Managed Care Organization</td>
			</tr>
			<tr>
				<td>
					<code>I3</code>
				</td>
				<td>Independent Physicians Association (IPA)</td>
			</tr>
			<tr>
				<td>
					<code>PR</code>
				</td>
				<td>Payer</td>
			</tr>
			<tr>
				<td>
					<code>P5</code>
				</td>
				<td>Plan Sponsor</td>
			</tr>
			<tr>
				<td>
					<code>13</code>
				</td>
				<td>Contracted Service Provider</td>
			</tr>
			<tr>
				<td>
					<code>1P</code>
				</td>
				<td>Provider</td>
			</tr>
		</table>
	</section>
</div>
