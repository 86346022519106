import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { AuthUser } from '../models/auth-user';
import { environment } from '../../../environments/environment';

import { Amplify } from 'aws-amplify'
import { signIn, confirmSignIn, fetchAuthSession, AuthSession, signOut, fetchMFAPreference, setUpTOTP, verifyTOTPSetup, updateMFAPreference } from 'aws-amplify/auth'

import { generateQRCode } from "../helpers/authenticator"

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: environment.ClientId,
      userPoolId: environment.UserPoolId
    }
  }
})

@Injectable({
  providedIn: 'root',
})

export class AuthService {

  public logout$ = new Subject<void>();

  async getUserGroups(): Promise<string[]> {
    const session = await fetchAuthSession()

    if (
      !session ||
      !session.tokens ||
      !session.tokens.accessToken ||
      !session.tokens.accessToken.payload ||
      !session.tokens.accessToken.payload["cognito:groups"]
    ) {
      return []
    }

    return session.tokens.accessToken.payload["cognito:groups"] as string[]
  }

  async hasGroup(groupName): Promise<boolean> {
    const groups = await this.getUserGroups()
    return groups.includes(groupName)
  }

  async login(
    email: string,
    password: string
  ) {
    if (
      !email ||
      !password
     ) {
      throw COGNITO_RESPONSES.invalidEmailAddress
    }

    try {
      const res = await signIn({
        username: email,
        password
      })

      if (res.nextStep) {
        if (
          res.nextStep.signInStep &&
          res.nextStep.signInStep == "CONFIRM_SIGN_IN_WITH_TOTP_CODE"
        ) {
          throw COGNITO_RESPONSES.mfaRequired
        }
      }

      const preference = await fetchMFAPreference()

      if (
        !preference.enabled &&
        environment.name != "local" &&
        environment.name != "dev"
      ) {
        throw COGNITO_RESPONSES.mfaSetup
      }
    } catch (error: any) {
      if (error.message == "There is already a signed in user.") {
        return
      }

      throw error
    }
  }

  async getQRCode(
    email: string
  ) {
    const res = await setUpTOTP()
    const code = await generateQRCode(email, res.sharedSecret)
    return code
  }

  async submitSoftwareToken(
    code: string
  ) {
    if (!code) {
      throw COGNITO_RESPONSES.invalidEmailAddress
    }

    await confirmSignIn({
      challengeResponse: code
    })
  }

  async verifySoftwareToken(
    code: string
  ) {
    if (
      !code
    ) {
      throw COGNITO_RESPONSES.invalidEmailAddress
    }

    const res = await verifyTOTPSetup({
      code
    })

    await updateMFAPreference({
      totp: "ENABLED"
    })
  }

  public async getSession(): Promise<AuthSession> {
    const session = await fetchAuthSession()

    if (
      !session ||
      !session.tokens
    ) {
      throw COGNITO_RESPONSES.invalidSession
    }

    return session
  }

  public async getAccessToken(): Promise<string|null> {
    const session = await this.getSession()
    return session.tokens.accessToken.toString()
  }

  async logout() {
    this.logout$.next()

    await signOut()
  }
}

export enum COGNITO_RESPONSES {
  invalidEmailAddress,
  invalidPassword,
  newPasswordRequired,
  mfaRequired,
  mfaSetup,
  invalidSession
}
