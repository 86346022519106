export class PayerDiscovery {
	
	constructor(
		public id: number,
		public payerName: string,
		public payerId: string,
		public pattern: string,
		public entityIdentifierCode: string
	) {}

	static fromJson(data: any): PayerDiscovery {
		return new PayerDiscovery(
			data.id,
			data.payerName,
			data.payerId,
			data.pattern,
			data.entityIdentifierCode
		)
	}
}
