import { Component } from '@angular/core';
import { AdminService } from "../@shared/services/admin.service"
import { Payer } from "../@shared/models/payer"
import { Title } from "@angular/platform-browser"

@Component({
  selector: 'app-payers',
  templateUrl: './payers.component.html'
})
export class PayersComponent {

  payers: Payer[] = []

  constructor(
    private adminService: AdminService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Payers - Payer Intelligence")
    this.getPayers()
  }

  async getPayers() {
    this.payers = await this.adminService.getPayers()
  }
}
