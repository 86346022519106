<div >
	<span *ngIf="!isEditing" class="display">{{ displayValue }}</span>
	<input
		*ngIf="isEditing"
		type="number"
		min="-1"
		step="1"
		pattern="-?[0-9]+"
		class="input"
		[(ngModel)]="inputQuotaValue"
		(ngModelChange)="onQuotaChange($event)"
		required
	>
	<a class="button" style="margin-left: 10px;" (click)="toggleEdit()">{{ isEditing ? 'Confirm' : 'Edit' }}</a>
	<div *ngIf="isEditing">
		<img class="img" src="assets/info-circle.svg" />
		<span class='info'>-1 = no limit, 0 = blocked</span>
	</div>
</div>