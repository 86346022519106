import { Component, Input, Output, EventEmitter } from '@angular/core';

export enum SubMenuName {
  Payers = 'payers',
  Specialties = 'specialties',
}

export type SubMenuInputConfig = {
  name: SubMenuName,
  elements: ElementConfig[]
}

type ElementConfig = {
  name: string,
  link: string,
}

export type SubMenuClickedEvent = {
  name: SubMenuName,
  elementName: string
}

@Component({
  selector: 'sub-menu',
  templateUrl: './sub-menu.component.html',
})
export class SebMenuComponent {
  @Input() inputConfig: SubMenuInputConfig
  @Output() elementClicked = new EventEmitter<SubMenuClickedEvent>();

  onElementClicked(name: string) {
    this.elementClicked.emit({
      name: this.inputConfig.name,
      elementName: name
    });
  }
}
