import { Component } from '@angular/core'
import { AdminService } from "../@shared/services/admin.service"
import { Title } from "@angular/platform-browser"
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-specialty-model-payer',
  templateUrl: './specialty-model-payer.component.html',
  styles: [`
    img {
      width: 2em;
      vertical-align: middle;
    }
  `]
})
export class SpecialtyModelPayerComponent {

  specialtyId: string|null = null
  modelId: string|null = null
  payerConfigs: { payerId: string, payerName: string, status: number}[] = []

  constructor(
    private adminService: AdminService,
    private route: ActivatedRoute,
    private titleService: Title
  ) {}

  async ngOnInit() {
    this.titleService.setTitle("SpecialtyModelPayerConfig")

    this.specialtyId = this.route.snapshot.paramMap.get("id")
    this.modelId = this.route.snapshot.paramMap.get("modelId")

    this.getSpecialtyModelPayerConfigs(this.specialtyId, this.modelId)
  }

  async getSpecialtyModelPayerConfigs(specialtyId: string, modelId: string) {
    this.payerConfigs = await this.adminService.getSpecialtyModelPayerConfigs(specialtyId, modelId)
  }

  async didClickUpdateConfig(config: typeof this.payerConfigs[0]) {
    const payerId = config.payerId
    const status = config.status != 1 ? 1 : 0

    try {
      const res = await this.adminService.updateSpecialtyModelPayerConfigStatus(
        this.specialtyId,
        this.modelId,
        [payerId],
        status)

      if (res == null ||
        res == undefined) {
        alert("Something went wrong")
        return
      }

      this.getSpecialtyModelPayerConfigs(this.specialtyId, this.modelId)
    } catch (error: any) {
      alert("Something went wrong")
      console.log(error)
    }
  }

  async didClickDisableAll() {
    const payerIds = this.payerConfigs.filter(p => p.status == 1).map(p => p.payerId)

    try {
      const res = await this.adminService.updateSpecialtyModelPayerConfigStatus(
        this.specialtyId,
        this.modelId,
        payerIds,
        0)

      if (res == null ||
        res == undefined) {
        alert("Something went wrong")
        return
      }

      this.getSpecialtyModelPayerConfigs(this.specialtyId, this.modelId)
    } catch (error: any) {
      alert("Something went wrong")
      console.log(error)
    }
  }
}

