import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { PayersComponent } from './payers/payers.component';
import { PayerComponent } from './payer/payer.component';
import { AuthGuard } from './@shared/guards/auth.guard';
import { VerificationComponent } from "./verification/verification.component"
import { CreatePayerComponent } from "./create-payer/create-payer.component"
import { EditPayerComponent } from "./edit-payer/edit-payer.component"
import { CustomersComponent } from "./customers/customers.component"
import { CustomerComponent } from "./customer/customer.component"
import { CreateCustomerComponent } from "./create-customer/create-customer.component"
import { DiscoveryComponent } from "./discovery/discovery.component"
import { DiscoveryDetailComponent } from "./discovery-detail/discovery-detail.component"
import { AuthenticatorComponent } from "./authenticator/authenticator.component"
import { TotpComponent } from "./totp/totp.component"
import { TaxonomyCodeComponent } from "./taxonomy-code/taxonomy-code.component"
import { SpecialtiesComponent } from "./specialties/specialties.component"
import { SpecialtyComponent } from "./specialty/specialty.component"
import { TaxonomyCodesComponent } from "./taxonomy-codes/taxonomy-codes.component"
import { TeamComponent } from './team/team.component';
import { SpecialtyModelPayerComponent } from './specialty-model-payer-config/specialty-model-payer.component';
import { PayerRoutingComponent } from "./payer-routing/payer-routing.component"
import { PayerDiscoveryComponent } from "./payer-discovery/payer-discovery.component"
import { PayerMappingComponent } from "./payer-mapping/payer-mapping.component"

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'authenticator',
    component: AuthenticatorComponent
  },
  {
    path: 'totp',
    component: TotpComponent
  },
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'verification/:id',
        component: VerificationComponent
      },
      {
        path: 'payers',
        component: PayersComponent
      },
      {
        path: 'discovery',
        component: DiscoveryComponent
      },
      {
        path: 'discovery/:id',
        component: DiscoveryDetailComponent
      },
      {
        path: 'create-payer',
        component: CreatePayerComponent
      },
      {
        path: 'payers/:id',
        component: PayerComponent
      },
      {
        path: 'payers/:id/specialties/:specialtyId',
        component: TaxonomyCodeComponent
      },
      {
        path: 'payers/:id/edit',
        component: EditPayerComponent
      },
      {
        path: 'customers',
        component: CustomersComponent
      },
      {
        path: 'customers/:id',
        component: CustomerComponent
      },
      {
        path: 'create-customer',
        component: CreateCustomerComponent
      },
      {
        path: 'specialties',
        component: SpecialtiesComponent
      },
      {
        path: 'specialties/:id',
        component: SpecialtyComponent
      },
      {
        path: 'specialties/:id/models/:modelId',
        component: SpecialtyModelPayerComponent
      },
      {
        path: 'taxonomy-codes',
        component: TaxonomyCodesComponent
      },
      {
        path: 'team',
        component: TeamComponent
      },
      {
        path: 'payer-intelligence',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'routing'
          },
          {
            path: 'routing',
            component: PayerRoutingComponent
          },
          {
            path: 'discovery',
            component: PayerDiscoveryComponent
          },
          {
            path: 'mapping',
            component: PayerMappingComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
