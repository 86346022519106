<div class="dashboard" [class.external]="isExternalUser">
	<div class="side-bar">
		<ul>
			<li class="logo"><a [routerLink]="['/']"><img src="assets/logo-white.svg"/></a></li>
			<li>
				<a [routerLink]="['/']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" (click)="resetMenus()">
					<img class="default-image" src="assets/home-smile.svg" />
					<img class="active-image" src="assets/home-smile-active.svg" />
					<span>Dashboard</span>
				</a>
			</li>
			<li>
				<a (click)="toggleMenu('payers')">
					<img class="default-image" src="assets/organizations.svg" />
					<span>Payer Intelligence</span>
					<img class="caret-image" [src]="showPayersMenu ? 'assets/caret-menu-up.svg' : 'assets/caret-menu-down.svg'" />
				</a>
				<sub-menu
					[inputConfig]="payersSubmenuConfig"
					(elementClicked)="handleElementClicked($event)"
					*ngIf="showPayersMenu">
				</sub-menu>
			</li>
			<li>
				<a [routerLink]="['/customers']" routerLinkActive="active-link" (click)="resetMenus()">
					<img class="default-image" src="assets/users-03.svg" />
					<img class="active-image" src="assets/users-03-active.svg" />
					<span>Customers</span>
				</a>
			</li>
			<li>
				<a (click)="toggleMenu('specialties')">
					<img class="default-image" src="assets/first-aid-kit.svg" />
					<span>Specialties</span>
					<img class="caret-image" [src]="showSpecialtiesMenu ? 'assets/caret-menu-up.svg' : 'assets/caret-menu-down.svg'" />
				</a>
				<sub-menu
					[inputConfig]="specialtiesSubmenuConfig"
					(elementClicked)="handleElementClicked($event)"
					*ngIf="showSpecialtiesMenu">
				</sub-menu>
			</li>
			<li>
				<a [routerLink]="['/discovery']" routerLinkActive="active-link" (click)="resetMenus()">
					<img class="default-image" src="assets/users-03.svg" />
					<img class="active-image" src="assets/users-03-active.svg" />
					<span>Discovery</span>
				</a>
			</li>
			<li *ngIf="isAdmin">
				<a [routerLink]="['/team']" routerLinkActive="active-link" (click)="resetMenus()">
					<img class="default-image" src="assets/users-03.svg" />
					<img class="active-image" src="assets/users-03-active.svg" />
					<span>Team</span>
				</a>
			</li>
		</ul>
		<div *ngIf="version" class="version-info">
			{{ version }}
		</div>
	</div>
	<router-outlet></router-outlet>
</div>