import { Component, Input } from '@angular/core'
import { NoteService } from "../@shared/services/note.service"
import { Note } from '../@shared/models/note'
import { AuthService } from "../@shared/services/auth.service"
import { Title } from "@angular/platform-browser"

@Component({
  selector: 'app-verification-notes',
  templateUrl: './verification-notes.component.html'
})
export class VerificationNotesComponent {
  @Input()
  verificationId: string|null
  @Input()
  notes: Note[]

  content: string
  jiraTicketTitle: string
  jiraTicketDescription: string
  isExternalUser = false

  constructor(
    private authService: AuthService,
    private noteService: NoteService,
    private titleService: Title
  ) {}

  async ngOnInit() {
    this.titleService.setTitle("Notes - Verification")
    this.isExternalUser = await this.authService.hasGroup("external")
    this.getNotes()
  }

  async getNotes() {
    this.notes = await this.noteService.getNotes(this.verificationId)
  }

  async didClickSendNote() {
    if (this.verificationId == null ||
      !this.content) {
      alert("Something went wrong, please try again")
      return
    }

    await this.noteService.createNote(
      this.verificationId,
      this.content
    )

    this.content = ""

    this.getNotes()
  }

  async didClickCreateJiraTicket() {
    if (this.verificationId == null ||
      !this.jiraTicketTitle ||
      !this.jiraTicketDescription
    ) {
      alert("Something went wrong, please try again")
      return
    }

    await this.noteService.createNote(
      this.verificationId,
      null,
      this.jiraTicketTitle,
      this.jiraTicketDescription)

    this.jiraTicketTitle = ""
    this.jiraTicketDescription = ""

    alert("Ticket created")
  }
}
