import { Directive, OnDestroy } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";

@Directive()
export abstract class TabManagerService implements OnDestroy {
  private DEFAULT_TAB = 'main';
  private subscription: Subscription;

  tab = this.DEFAULT_TAB

  constructor(private srvRouter: Router, mainTabName = 'main') {
    this.DEFAULT_TAB = mainTabName

    this.tab = this.srvRouter.routerState.snapshot.root?.fragment ?? this.DEFAULT_TAB


    this.subscription = this.srvRouter.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const fragment = event.urlAfterRedirects.split('#')[1];
        this.tab = fragment ?? this.DEFAULT_TAB;
      }
    });
  }

  navigateToTab(tabName: string): void {
    this.tab = tabName;
    this.srvRouter.navigate([], { fragment: tabName });
  }

  destroy(): void {
    this.subscription.unsubscribe();
  }

  // inheriting component must implement OnDestroy and use destroy() method
  abstract ngOnDestroy(): void
}
