<table>
	<thead>
		<td></td>
		<td>ID</td>
		<td>Payer</td>
		<td>Related Entity</td>
		<td>Entity Identifier Code</td>
		<td></td>
	</thead>
	<tbody *ngFor="let rule of rules">
		<tr>
			<td>
				<a (click)="didClickOpen(rule)" class="arrow-right" [class.rotated]="rule.isOpen"></a>
			</td>
			<td>
				{{ rule.shortRelatedEntityId }}
			</td>
			<td>
				<a [routerLink]="['/payers', rule.payerId]">
					{{ rule.payerName }}
				</a>
			</td>
			<td>
				<span *ngIf="rule.isMapped">
					<a [routerLink]="['/payers', rule.relatedEntityPayerId]">
						{{ rule.relatedEntityPayerName }}
					</a>
				</span>
				<a class="button" *ngIf="!rule.isMapped && !rule.selectPayer" (click)="rule.selectPayer = true">Select Payer</a>
				<select *ngIf="!rule.isMapped && rule.selectPayer == true" [(ngModel)]="rule.selectedRelatedEntityPayerId">
					<option *ngFor="let payer of payers" [value]="payer.payerId">
						{{ payer.payerName }}
					</option>
				</select>
			</td>
			<td>
				<span *ngIf="rule.isMapped">
					{{ rule.entityIdentifierCode }}
				</span>
				<select *ngIf="!rule.isMapped" [(ngModel)]="rule.selectedEntityIdentifierCode">
					<option *ngFor="let entityIdentifier of entityIdentifiers" [value]="entityIdentifier.code">
						{{ entityIdentifier.label }}
					</option>
				</select>
			</td>
			<td>
				<a *ngIf="!rule.isMapped" class="button" (click)="didClickSubmit(rule)">Submit</a>
			</td>
		</tr>
		<tr *ngIf="rule.isOpen" class="verification">
			<td colspan="6">
				<pre>{{ rule.data }}</pre>
			</td>
		</tr>
	</tbody>
</table>