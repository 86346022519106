<div class="header">
	<h1>Discovery</h1>
</div>
<table>
	<thead>
		<td>Pattern</td>
		<td>Payer</td>
		<td>Entity Identifier Code</td>
		<td></td>
	</thead>
	<tbody>
		<tr *ngFor="let rule of rules">
			<td>
				{{ rule.pattern }}
			</td>
			<td>
				<a [routerLink]="['/payers', rule.payerId]">
					{{ rule.payerName }}
				</a>
			</td>
			<td>
				{{ rule.entityIdentifierCode }}
			</td>
			<td>
				<a class="button" (click)="didClickDeleteRule(rule.id)">
					Delete
				</a>
			</td>
		</tr>
		<tr>
			<td>
				<input type="text" [(ngModel)]="pattern" placeholder="Enter a regex pattern" />
			</td>
			<td>
				<select [(ngModel)]="payerId">
					<option value="">
						Select a payer
					</option>
					<option *ngFor="let payer of payers" [value]="payer.payerId">
						{{ payer.payerName }}
					</option>
				</select>
			</td>
			<td>
				<select [(ngModel)]="entityIdentifierCode">
					<option value="">
						Select an entity identifier code
					</option>
					<option *ngFor="let entityIdentifier of entityIdentifiers" [value]="entityIdentifier.code">
						{{ entityIdentifier.name }} - {{ entityIdentifier.code }}
					</option>
				</select>
			</td>
			<td>
				<a class="button" (click)="didClickAddRule()">Add</a>
			</td>
		</tr>
	</tbody>
</table>
<div class="row">
	<section>
		<h4>Payer Discovery</h4>
		<p>
			Payer Discovery rules find related payers based on the member ID pattern. Payer Discovery occurs <b>after</b> sending an inital request to a payer and receiving a successful response.
		</p>
		<p>
			For example, we might have sent a request to Blue Cross Blue Shield of Texas. The member ID is <code>ZGN123</code>. We have a Payer Discovery rule with the pattern <code>^ZGN</code> to send the request to Magellan Healthcare to see if the patient has active coverage. The purpose of this is typically to discover carve outs.
		</p>
		<p>
			If a Payer Discovery rule successfully finds a related payer, the payer will be returned to the customer in the <code>relatedEntities</code> array.
		</p>
		<h4>Member ID Patterns</h4>
		<p>
			Member ID Patterns mut be valid regular expressions. You can use <a href="https://regexr.com" target="_blank">this tool</a> to create regular expressions. See some examples below:
		</p>
		<table>
			<tr>
				<td>
					<code (click)="didClickSetPattern($event)">^W</code>
				</td>
				<td>Any string beginning with W</td>
			</tr>
			<tr>
				<td>
					<code (click)="didClickSetPattern($event)">^C[0-9]&#123;8&#125;$</code>
				</td>
				<td>Any string beginning with C, followed by exactly 8 digits</td>
			</tr>
			<tr>
				<td>
					<code (click)="didClickSetPattern($event)">^ABC[a-zA-Z0-9]&#123;9,12&#125;$</code>
				</td>
				<td>Any string beginning with ABC, followed by between 9 and 12 characters</td>
			</tr>
			<tr>
				<td>
					<code (click)="didClickSetPattern($event)">^[0-9]&#123;3&#125;ABC$</code>
				</td>
				<td>Any string beginning with three digits, followed by ABC</td>
			</tr>
		</table>
		<h4>Entity Identifier Codes</h4>
		<p>
			Entity Identifier Codes describe the role of the payer. If a Payer Discovery request is successful, the specified entity identifier code will be returned to the customer. <a href="https://ediacademy.com/blog/x12-n101-entity-identifier-codes/" target="_blank">This</a> is a useful resource for available entity identifier codes. We currently support the following entity identifier codes:
		</p>
		<table>
			<tr>
				<td>
					<code>PRP</code>
				</td>
				<td>Primary Payer</td>
			</tr>
			<tr>
				<td>
					<code>SEP</code>
				</td>
				<td>Secondary Payer</td>
			</tr>
			<tr>
				<td>
					<code>TTP</code>
				</td>
				<td>Tertiary Payer</td>
			</tr>
			<tr>
				<td>
					<code>X3</code>
				</td>
				<td>Utilization Management Organization</td>
			</tr>
			<tr>
				<td>
					<code>VN</code>
				</td>
				<td>Vendor</td>
			</tr>
			<tr>
				<td>
					<code>TV</code>
				</td>
				<td>Third Party Administrator</td>
			</tr>
			<tr>
				<td>
					<code>Y2</code>
				</td>
				<td>Managed Care Organization</td>
			</tr>
			<tr>
				<td>
					<code>I3</code>
				</td>
				<td>Independent Physicians Association (IPA)</td>
			</tr>
		</table>
	</section>
</div>
