<div class="row">
	<section class="notes">
		<div class="note" *ngFor="let note of notes">
			<div class="avatar" [ngStyle]="{'background-color': note.avatarBackgroundColor }">
				{{ note.initials }}
			</div>
			<div class="content-container">
				<div class="meta"><span>{{ note.fullName }}</span>{{ note.created | date: 'MM/dd/yyyy, HH:mm:ss' }}</div>
				<div class="content">{{ note.content }}</div>
			</div>
		</div>
		<div class="form">
			<input type="text" [(ngModel)]="content" (keyup.enter)="didClickSendNote()" />
			<div class="flex">
				<a class="button" (click)="didClickSendNote()">Send</a>
			</div>
		</div>
	</section>
</div>

<div class="row" *ngIf="!isExternalUser">
	<section class="notes">
		<h4>Jira Ticket</h4>
		<div class="form">
			<input type="text" [(ngModel)]="jiraTicketTitle" placeholder="Title" />
			<input type="text" [(ngModel)]="jiraTicketDescription" (keyup.enter)="didClickCreateJiraTicket()" placeholder="Description" />
			<div class="flex">
				<a class="button" (click)="didClickCreateJiraTicket()">Create</a>
			</div>
		</div>
	</section>
</div>
