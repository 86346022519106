import { ENTITY_IDENTIFIERS, STATES_HASH } from "../constants/data"
import { Payer } from "../models/payer"

const parseData = (data: string|null): {
	entityIdentifierCode: string|null,
	nameLastOrOrganizationName: string|null,
	patientState: string|null
}|null => {
	if (data == null) {
		return null
	}

	try {
		const json: {
			entityIdentifierCode: string|null|undefined,
			nameLastOrOrganizationName: string|null|undefined,
			patientState: string|null|undefined
		} = JSON.parse(data)

		return {
			entityIdentifierCode: json.entityIdentifierCode || null,
			nameLastOrOrganizationName: json.nameLastOrOrganizationName || null,
			patientState: json.patientState || null
		}
	} catch (error: any) {
		return null
	}
}

export const getEntityIdentifierCode = (data: string|null): string|null => {
	const json = parseData(data)

	if (json == null) {
		return null
	}

	const entityIdentifierCode = json.entityIdentifierCode

	if (entityIdentifierCode == null) {
		return null
	}

	const entityIdentifierCodes = ENTITY_IDENTIFIERS.map((entityIdentifier) => {
		return entityIdentifier.code
	})

	if (entityIdentifierCodes.includes(entityIdentifierCode) == false) {
		return null
	}

	return entityIdentifierCode
}

export const getPayerId = (data: string|null, payerId: string, payers: Payer[]): string|null => {
	const json = parseData(data)

	if (json == null) {
		return null
	}

	let nameLastOrOrganizationName = json.nameLastOrOrganizationName
	const patientState = json.patientState

	if (nameLastOrOrganizationName == null) {
		return null
	}

	if (nameLastOrOrganizationName == "MAGELLAN") {
		nameLastOrOrganizationName = "MAGELLAN HEALTHCARE"
	} else if (nameLastOrOrganizationName.includes("MEDICARE") &&
		patientState != null) {
		const stateName = STATES_HASH[patientState]?.toUpperCase()

		if (stateName != undefined) {
			nameLastOrOrganizationName = `MEDICARE OF ${stateName}`
		}
	} else if (nameLastOrOrganizationName.includes("UHC")) {
		nameLastOrOrganizationName = "UNITEDHEALTHCARE"
	} else if (nameLastOrOrganizationName == "BCBS" &&
		patientState != null) {
		const stateName = STATES_HASH[patientState]?.toUpperCase()

		if (stateName != undefined) {
			nameLastOrOrganizationName = `BLUE CROSS BLUE SHIELD OF ${stateName}`
		}
	} else if (nameLastOrOrganizationName.includes("BCBS OF ")) {
		nameLastOrOrganizationName = nameLastOrOrganizationName.replace("BCBS", "BLUE CROSS BLUE SHIELD")
	} else if (nameLastOrOrganizationName == "BEHAVIORAL HEALTH SERVICE UNIT") {
		return payerId
	} else if (nameLastOrOrganizationName == "HORIZON BCBSNJ") {
		nameLastOrOrganizationName = "BLUE CROSS BLUE SHIELD OF NEW JERSEY"
	} else if (nameLastOrOrganizationName == "UNITED HEALTHCARE") {
		nameLastOrOrganizationName = "UNITEDHEALTHCARE"
	}

	for (const payer of payers) {

		const payerName = payer.payerName.toUpperCase()

		if (nameLastOrOrganizationName == payerName) {
			return payer.payerId
		}
	}

	return null
}
