export const getClearingHouseName = (
	clearingHouseIdentifier: number|null|undefined
): string|null => {
	if (clearingHouseIdentifier == null ||
		clearingHouseIdentifier == undefined) {
		return null
	}

	const clearingHouses = [
		"Change Healthcare",
		"Availity",
		"Office Ally",
		"UnitedHealthcare"
	]

	if (clearingHouseIdentifier >= clearingHouses.length) {
		return "Unknown"
	}

	return clearingHouses[clearingHouseIdentifier]
}
