<div class="header">
	<h1>Create Payer</h1>
</div>
<div class="admin">
	<section>
		<form [formGroup]="form" (ngSubmit)="didClickCreatePayer()">
			<label>Payer Name</label>
			<input type="text" formControlName="payerName" required placeholder="Enter payer name">
			<label>Change ID</label>
			<input type="text" formControlName="changeHealthcarePayerId" placeholder="Enter Change Healthcare payer ID">
			<label>Payer ID</label>
			<input type="text" formControlName="standardPayerId" placeholder="Enter the standard payer ID">
			<p>If the payer is for payer mapping but we don't want to include it in our customer-facing payer list, leave the Change Healthcare payer ID and standard payer ID empty.</p>
			<button>Submit</button>
		</form>
	</section>
</div>
