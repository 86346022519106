export class PayerRoute {
	
	constructor(
		public id: number,
		public payerName: string,
		public payerId: string,
		public pattern: string
	) {}

	static fromJson(data: any): PayerRoute {
		return new PayerRoute(
			data.id,
			data.payerName,
			data.payerId,
			data.pattern
		)
	}
}
