<div class="header">
	<h1>Routing</h1>
</div>
<table>
	<thead>
		<td>Pattern</td>
		<td>Payer</td>
		<td></td>
	</thead>
	<tbody>
		<tr *ngFor="let rule of rules">
			<td>
				{{ rule.pattern }}
			</td>
			<td>
				<a [routerLink]="['/payers', rule.payerId]">
					{{ rule.payerName }}
				</a>
			</td>
			<td>
				<a class="button" (click)="didClickDeleteRule(rule.id)">
					Delete
				</a>
			</td>
		</tr>
		<tr>
			<td>
				<input type="text" [(ngModel)]="pattern" placeholder="Enter a regex pattern" />
			</td>
			<td>
				<select [(ngModel)]="payerId">
					<option value="">
						Select a payer
					</option>
					<option *ngFor="let payer of payers" [value]="payer.payerId">
						{{ payer.payerName }}
					</option>
				</select>
			</td>
			<td>
				<a class="button" (click)="didClickAddRule()">Add</a>
			</td>
		</tr>
	</tbody>
</table>
<div class="row">
	<section>
		<h4>Payer Routing</h4>
		<p>
			Payer Routing rules are member ID patterns that route a request to a different payer than the one specified by the customer. Payer Routing occurs <b>before</b> sending an inital request to a payer.
		</p>
		<p>
			For example, we might have a request with Cigna specified. The member ID is <code>W1234</code>. We have a Payer Routing rule of <code>^W</code> linked to Aetna. This rule says that any member ID beginning with <code>W</code> should be sent to Aetna, because we know that this is typically the case. Our example request would be first sent to Aetna instead of Cigna. If the request to Aetna failed, we would attempt a request to Cigna.
		</p>
		<p>
			If a Payer Routing rule successfully re-routes the request, the payer in the request will be updated and returned to the customer.
		</p>
		<h4>Member ID Patterns</h4>
		<p>
			Member ID patterns mut be valid regular expressions. You can use <a href="https://regexr.com" target="_blank">this tool</a> to create regular expressions. See some examples below:
		</p>
		<table>
			<tr>
				<td>
					<code (click)="didClickSetPattern($event)">^W</code>
				</td>
				<td>Any string beginning with W</td>
			</tr>
			<tr>
				<td>
					<code (click)="didClickSetPattern($event)">^C[0-9]&#123;8&#125;$</code>
				</td>
				<td>Any string beginning with C, followed by exactly 8 digits</td>
			</tr>
			<tr>
				<td>
					<code (click)="didClickSetPattern($event)">^ABC[a-zA-Z0-9]&#123;9,12&#125;$</code>
				</td>
				<td>Any string beginning with ABC, followed by between 9 and 12 characters</td>
			</tr>
			<tr>
				<td>
					<code (click)="didClickSetPattern($event)">^[0-9]&#123;3&#125;ABC$</code>
				</td>
				<td>Any string beginning with three digits, followed by ABC</td>
			</tr>
		</table>
	</section>
</div>
