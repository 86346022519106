import { Component, OnDestroy } from '@angular/core';
import { AdminService } from "../@shared/services/admin.service"
import { Customer } from "../@shared/models/customer"
import { Title } from "@angular/platform-browser"
import { ActivatedRoute, Router } from '@angular/router'
import { STATES } from "../@shared/constants/data"
import { Papa } from "ngx-papaparse"
import { TabManagerService } from '../@shared/services/tab-manager.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styles: [`
    .invalid {
      border: 2px solid red;
      color: red;
    }
    .disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
        background-color: #ccc;
        color: #666;
        border: 1px solid #ddd;
    }
  `]
})
export class CustomerComponent extends TabManagerService implements OnDestroy {

  customer: Customer|null = null
  customerId: string
  users: any[] = []
  providers: any[] = []
  payers: any[] = []
  states = STATES
  payerId = ""
  state = ""
  npi = null

  initDiscoveryQuota: number = -1
  initVerificationQuota: number = -1
  discoveryQuota: number = -1
  verificationQuota: number = -1
  isEditingDiscovery = false
  isEditingVerification = false

  constructor(
    private papa: Papa,
    private adminService: AdminService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title
  ) {
    super(router)
  }

  async ngOnInit() {
    this.titleService.setTitle("Customer")
    this.customerId = this.route.snapshot.paramMap.get("id")
    this.getCustomer()
    this.getPayers()
  }

  ngOnDestroy() {
    this.destroy()
  }

  async getPayers() {
    this.payers = await this.adminService.getPayers()
  }

  async getCustomer() {
    const res = await this.adminService.getCustomer(this.customerId)
    this.customer = res.customer
    this.users = res.users
    this.providers = res.providers

    this.discoveryQuota = this.customer.maxProductionDiscoveryCount === null ? -1 : this.customer.maxProductionDiscoveryCount
    this.initDiscoveryQuota = this.discoveryQuota
    this.verificationQuota = this.customer.maxProductionVerificationCount === null ? -1 : this.customer.maxProductionVerificationCount
    this.initVerificationQuota = this.verificationQuota
  }

  async didClickEnableProvider(id: number) {
    const customerId = this.customerId

    try {
      await this.adminService.updateCustomerProvider(customerId, id)

      this.getCustomer()
    } catch (error: any) {
      alert("Something went wrong")
      console.log(error)
    }
  }

  async didClickAddProvider() {
    const customerId = this.customerId
    const payerId = this.payerId
    const state = this.state
    const npi = this.npi

    if (payerId == "") {
      alert("Select a Payer")
      return
    } else if (state == "") {
      alert("Select a state")
      return
    } else if (npi == undefined ||
      npi == null ||
      npi == "") {
      alert("Enter an NPI")
      return
    }

    try {
      await this.adminService.createCustomerProvider(
        customerId,
        payerId,
        state,
        npi)

      this.payerId = ""
      this.state = ""
      this.npi = ""

      this.getCustomer()
    } catch (error: any) {
      alert("Something went wrong")
      console.log(error)
    }
  }


  async didClickAddProviders(event: any) {
    const customerId = this.customerId

    const file = event.target.files[0]

    if (!file) {
      alert("Invalid file")
      return
    }

    this.papa.parse(file, {
      header: true,
      transformHeader: (h) => {
        return h.trim()
      },
      complete: async (results, file) => {
        if (!results || !results.data) {
          alert("Invalid file")
          return
        } else if (results.data.length > 500) {
          alert("The CSV must contain a maximum of 500 rows")
          return
        }

        const providers = results.data

        for (const provider of providers) {
          if (provider.payerId == undefined ||
            provider.payerId.length != 36 ||
            provider.npi == undefined ||
            provider.npi.length != 10 ||
            provider.state == undefined ||
            provider.state.length != 2) {
            alert("Invalid CSV")
            return
          }
        }

        try {
          await this.adminService.createCustomerProviders(
            customerId,
            providers)

          this.getCustomer()
        } catch (error: any) {
          alert("Something went wrong")
        }
      }
    })
  }

  async didClickResetUserPassword(user: any) {
    const customerId = this.customerId
    const cognitoId = user.cognitoId

    const confirmed = confirm(`Are you sure you want to reset the password for ${user.emailAddress}?`)

    if (!confirmed) {
      return
    }

    try {
      await this.adminService.resetUserPassword(
        customerId,
        cognitoId)

      alert("Password reset")
    } catch (error: any) {
      alert("Something went wrong")
      console.log(error)
    }
  }


  async didClickResetMfa(user: any) {
    const customerId = this.customerId
    const cognitoId = user.cognitoId

    const confirmed = confirm(`Are you sure you want to reset the MFA for ${user.emailAddress}?`)

    if (!confirmed) {
      return
    }

    try {
      await this.adminService.resetUserMfa(
        customerId,
        cognitoId)

      alert("MFA reset")
    } catch (error: any) {
      alert("Something went wrong")
      console.log(error)
    }
  }

  onQuotaChange(value: number, type: 'discovery' | 'verification') {
    if (type == 'discovery') {
      this.discoveryQuota = value
    } else if (type == 'verification') {
      this.verificationQuota = value
    }
  }

  onEditChanged(value: boolean, type: 'discovery' | 'verification') {
    if (type == 'discovery') {
      this.isEditingDiscovery = value
    } else if (type == 'verification') {
      this.isEditingVerification = value
    }
  }

  async submitQuota() {
    const discoveryQuota = this.discoveryQuota < 0 ? null : this.discoveryQuota
    const verificationQuota = this.verificationQuota < 0 ? null : this.verificationQuota

    try {
      await this.adminService.updateCustomer(
        this.customerId,
        discoveryQuota,
        verificationQuota
      )

      await this.getCustomer()
    } catch (error: any) {
      alert("Something went wrong")
      console.log(error)
    }
  }

  hasQuotaChanged(): boolean {
    return this.discoveryQuota != this.initDiscoveryQuota ||
      this.verificationQuota != this.initVerificationQuota
  }
}

