import { Component } from '@angular/core';
import { PayerIntelligenceService } from "../@shared/services/payer-intelligence.service"
import { Title } from "@angular/platform-browser"
import { PayerDiscovery } from "../@shared/models/payer-discovery"
import { Payer } from "../@shared/models/payer"
import { AdminService } from "../@shared/services/admin.service"

@Component({
  selector: 'app-payer-discovery',
  templateUrl: './payer-discovery.component.html'
})
export class PayerDiscoveryComponent {

  rules: PayerDiscovery[] = []
  payers: Payer[] = []
  entityIdentifiers = [
    {
      "name": "Vendor",
      "code": "VN"
    }
  ]

  pattern: string|null = null
  payerId: string = ""
  entityIdentifierCode: string = ""

  constructor(
    private payerIntelligenceService: PayerIntelligenceService,
    private titleService: Title,
    private adminService: AdminService
  ) {}

  async ngOnInit() {
    this.titleService.setTitle("Discovery - Payer Intelligence")

    this.getRules()

    this.payers = await this.adminService.getPayers()
  }

  async getRules() {
    this.rules = await this.payerIntelligenceService.getPayerDiscoveryRules()
  }

  async didClickDeleteRule(id: number) {
    if (id == null ||
      id == undefined) {
      return
    }

    const confirmed = confirm("Are you sure you want to delete this rule?")

    if (!confirmed) {
      return
    }

    try {
      await this.payerIntelligenceService.deletePayerDiscoveryRule(id)
      alert("Rule deleted")
      this.getRules()
    } catch (error: any) {
      alert("Something went wrong")
    }
  }

  didClickSetPattern(event: any) {
    if (event == null ||
      event == undefined) {
      return
    }

    const target = event.target as HTMLElement

    if (target == null ||
      target == undefined) {
      return
    }

    this.pattern = target.innerHTML
  }

  async didClickAddRule() {
    const pattern = this.pattern
    const payerId = this.payerId
    const entityIdentifierCode = this.entityIdentifierCode

    if (pattern == null ||
      pattern == undefined ||
      pattern == "") {
      alert("Enter a regex pattern")
      return
    } else if (payerId == null ||
      payerId == undefined ||
      payerId == "") {
      alert("Select a payer")
      return
    } else if (entityIdentifierCode == null ||
      entityIdentifierCode == undefined ||
      entityIdentifierCode == "") {
      alert("Select an entity identifier code")
      return
    }

    try {
      await this.payerIntelligenceService.createPayerDiscoveryRule(pattern, payerId, entityIdentifierCode)
      alert("Rule created")
      this.getRules()
    } catch (error) {
      alert("Something went wrong")
    } finally {
      this.pattern = null
      this.payerId = ""
      this.entityIdentifierCode = ""
    }
  }
}
